import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Helmet } from 'react-helmet';
import { CookiesProvider } from 'react-cookie';

import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import AppContext from 'AppContext';

import Dashboard from './dashboard/Dashboard';

import Home from './modules/Home';
import Plans from './modules/Plans';
import Page from './modules/Page';
import Loader from './modules/Loader';
import InvitePage from './modules/InvitePage';
import NoMatch from './modules/NoMatch';
import ErrorBoundary from './modules/ErrorBoundary';
import NotificationBar from 'modules/NotificationBar';
import Welcome from './modules/Welcome';
import WelcomeCustomer from './modules/WelcomeCustomer';
import NewBoard from 'dashboard/NewBoard';
import IceBreakerQuestions from './modules/IceBreakerQuestions';
import HomeRedirect from 'modules/HomeRedirect';
import OrgMembers from 'modules/OrgMembers';
import OrgInvitePage from 'modules/OrgInvitePage';
import SingleSignOn from 'modules/SingleSignOn';
import OrgPulse from 'pulse/OrgPulse';
import NewPulse from 'pulse/NewPulse';
import OrgSettings from 'modules/OrgSettings';
import DemoSurvey from 'pulse/DemoSurvey';

const theme = createTheme({
  typography: {
    fontFamily: ['Quicksand', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: '#209eff',
      contrastText: '#fff'
    },
    secondary: {
      main: '#ff68a8',
      contrastText: '#fff'
    },
    info: {
      main: '#209eff'
    },
    success: {
      main: '#e390db'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        borderRadius: '10px',
        boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.07)'
      },
      elevation1: {
        borderRadius: '10px',
        boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.07)'
      },
      rounded: {
        borderRadius: '10px'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgba(80, 80, 80, 0.89)',
        margin: '4px 0 !important',
        padding: '10px 12px',
        fontSize: '14px'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '10px'
      }
    }
  }
});

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [profile, setProfile] = useState();
  const [paymentMeta, setPaymentMeta] = useState();
  const [userMeta, setUserMeta] = useState();
  const [org, setOrg] = useState();
  const [orgs, setOrgs] = useState([]);
  const [isNewUser, setIsNewUser] = useState();
  const [hasError, setHasError] = useState();
  const isNewPlan = typeof paymentMeta === 'object' && paymentMeta.teleretro_isNewPlan;

  const switchOrg = ({ orgId }) => {
    if (org?.id === orgId) return;

    const selectedOrg = orgs.filter(({ id }) => id === orgId)[0];
    if (selectedOrg) {
      setOrg(selectedOrg);
    }
  };

  const updateOrg = ({ orgId, name, teamSettings, enabledModels }) => {
    const updatedOrgs = orgs.map((org) => {
      if (name && org?.id === orgId) {
        org.name = name;
      }
      if (teamSettings) {
        const { teamId, name } = teamSettings;
        const currentTeamSettings = org.teamsSettings && org.teamsSettings[teamId];
        if (name && currentTeamSettings && currentTeamSettings.name !== name) {
          currentTeamSettings.name = name;
        }
      }
      if (enabledModels) {
        org.enabledModels = enabledModels;
      }
      return org;
    });

    setOrgs(updatedOrgs);
  };

  const getPaymentData = useCallback(async ({ org }) => {
    const { id: orgId, customerId } = org;

    if (!customerId || org.fromTeam) {
      setPaymentMeta();
      return;
    }

    const paymentData = await axios
      .post(`/payment/org/${orgId}/get_subscriptions`, {
        customerId
      })
      .then(({ data }) => data)
      .catch((e) => {
        Sentry.captureException(e);
        return;
      });

    setPaymentMeta(paymentData);
  }, []);

  const getAppInfo = useCallback(async () => {
    const userMetadata = await axios
      .get('/api/user-meta')
      .then(({ data }) => data)
      .catch((e) => {
        setHasError(e);
      });

    if (!userMetadata) return;

    const { orgs, teams: userTeams } = userMetadata;
    const displayOrgs = [];

    // Check Org order should be persistent
    if (userTeams && userTeams.length > 0) {
      const teamOrgs = [];
      const orgsData = await Promise.all(
        userTeams.map(async (team) => {
          const org = await axios
            .get(`/api/team/${team.id}/org`)
            .then(({ data }) => data)
            .catch((e) => {
              setHasError(e);
            });
          const existingUserOrg = teamOrgs.filter((id) => id === org?.id)[0];
          const existingOrg = orgs.filter(({ id }) => id === org?.id)[0];

          if (org && !existingUserOrg && !existingOrg) {
            org.name = org.name || 'Joined Organization';
            org.fromTeam = true;
            teamOrgs.push(org.id);
            return org;
          }
        })
      );

      // filter out duplicated org or bad request
      displayOrgs.push(...orgsData.filter((org) => org));
    }

    const allOrgs = await Promise.all(
      orgs.map(async (orgData) => {
        const { id: orgId } = orgData;
        const org = await axios
          .get(`/api/org/${orgId}`)
          .then(({ data }) => data)
          .catch((e) => {
            setHasError(e);
          });

        if (org) {
          org.name = org.name || 'Your Organization';
          return org;
        }
      })
    );

    displayOrgs.push(...allOrgs.filter((org) => org));

    //Todo: multiple orgs in the future
    setOrg(displayOrgs[0]);
    setOrgs(displayOrgs);
    setUserMeta(userMetadata);
  }, []);

  useEffect(() => {
    if (org) {
      getPaymentData({ org });
    }
  }, [org, getPaymentData]);

  useEffect(() => {
    if (isAuthenticated) {
      try {
        axios.get(`/profile`).then(({ data: profileData }) => {
          const { sub } = profileData;

          if (sub && sub.substring && typeof sub.substring === 'function') {
            Sentry.configureScope((scope) => {
              scope.setUser({ id: sub.substring(sub.length - 8) });
            });
          } else {
            Sentry.captureMessage(`sub - ${sub}`);
          }

          setProfile(profileData);

          axios
            .get(`/api/user-profile`)
            .then(({ data }) => {
              if (!data.name && !data.nickname && !data.picture) {
                setIsNewUser(true);
              } else {
                setIsNewUser(false);
              }

              if (
                data.name === profileData.name &&
                data.nickname === profileData.nickname &&
                data.picture === profileData.picture &&
                data.given_name === profileData.given_name &&
                data.family_name === profileData.family_name
              ) {
                getAppInfo();
              } else {
                axios
                  .post(`/api/user-profile`, {
                    profileData
                  })
                  .then(() => {
                    getAppInfo();
                  })
                  .catch((e) => {
                    Sentry.captureException(e);
                    if (e?.response?.status === 401) {
                      setIsAuthenticated(false);
                    }
                  });
              }
            })
            .catch((e) => {
              Sentry.captureException(e);
              if (e?.response?.status === 401) {
                setIsAuthenticated(false);
              }
            });
        });
      } catch (e) {
        Sentry.captureException(e);
        if (e?.response?.status === 401) {
          setIsAuthenticated(false);
        }
      }
    }
  }, [isAuthenticated, getAppInfo]);

  useEffect(() => {
    axios
      .get('/status')
      .then(({ data }) => {
        console.log('Is authenticated', data);
        setIsAuthenticated(data);
      })
      .catch((err) => {
        setIsAuthenticated(false);
        Sentry.captureException(err);
      });
  }, []);

  if (isAuthenticated === undefined) return <Loader />;

  if (isAuthenticated && !profile) return <Loader />;

  return (
    <Router>
      <AppContext.Provider
        value={{
          profile,
          org,
          orgs,
          userMeta,
          paymentMeta,
          isAuthenticated,
          switchOrg,
          updateOrg
        }}
      >
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <div className="overview">
              <Switch>
                <Route path="/dashboard">
                  <Helmet>
                    <title>TeleRetro - Dashboard</title>
                  </Helmet>
                  <Home />
                  {hasError && (
                    <NotificationBar
                      message="Sorry something went wrong."
                      type="error"
                      closeCallback={() => setHasError()}
                    />
                  )}
                </Route>
                <Route exact path="/team/:teamId?/board/create">
                  <NewBoard />
                </Route>
                <Route exact path="/team/:teamId?/pulse-survey/create">
                  <NewPulse />
                </Route>
                <Route exact path="/plans">
                  <Helmet>
                    <title>TeleRetro - Plans</title>
                  </Helmet>
                  <Page>
                    <Plans />
                  </Page>
                </Route>
                <Route exact path="/settings">
                  <Helmet>
                    <title>TeleRetro - Settings</title>
                  </Helmet>
                  <Page>
                    <OrgSettings />
                  </Page>
                </Route>
                <Route path="/pulse">
                  <Helmet>
                    <title>TeleRetro - Pulse</title>
                  </Helmet>
                  <Page>
                    <OrgPulse />
                  </Page>
                </Route>
                <Route exact path="/board/:id">
                  <ErrorBoundary>
                    <Dashboard />
                  </ErrorBoundary>
                </Route>
                <Route exact path="/pulse-survey/:id">
                  <ErrorBoundary>
                    <Dashboard isPulseSurvey />
                  </ErrorBoundary>
                </Route>
                <Route exact path="/board">
                  <Redirect to="/dashboard" />
                </Route>
                <Route exact path="/admins">
                  <Helmet>
                    <title>TeleRetro - Admins</title>
                  </Helmet>
                  <Page>
                    <OrgMembers />
                  </Page>
                </Route>
                <Route exact path="/invite/team/:teamId/:inviteId">
                  <div className="invitePage">
                    <Page>
                      <InvitePage />
                    </Page>
                  </div>
                </Route>
                <Route exact path="/team/:teamId?/icebreaker-questions">
                  <Helmet>
                    <title>TeleRetro - Icebreaker Questions</title>
                  </Helmet>
                  <Page>
                    <IceBreakerQuestions />
                  </Page>
                </Route>
                <Route exact path="/invite/org/:orgId/:inviteId">
                  <div className="invitePage">
                    <Page>
                      <OrgInvitePage />
                    </Page>
                  </div>
                </Route>
                <Route exact path="/sso">
                  <div className="invitePage">
                    <Page>
                      <SingleSignOn />
                    </Page>
                  </div>
                </Route>
                <Route exact path="/survey-questions">
                  <Helmet>
                    <title>TeleRetro - Survey Questions</title>
                  </Helmet>
                  <DemoSurvey />
                </Route>
                <Route exact path="/">
                  <HomeRedirect />
                </Route>
                <Route path="*">
                  <Page>
                    <NoMatch />
                  </Page>
                </Route>
              </Switch>
              {isNewUser && <Welcome />}
              {isNewPlan && <WelcomeCustomer />}
            </div>
          </CookiesProvider>
        </ThemeProvider>
      </AppContext.Provider>
    </Router>
  );
};

export default App;
