import React from 'react';

import SurveyView from './SurveyView';
import useModels from 'hooks/useModels';
import useQuery from 'hooks/useQuery';

const DEFAULT_TOPICS = [
  {
    id: 'default-topic-1',
    icon: '🙂',
    title: 'Happiness',
    description:
      'Working at the company makes me feel content and fulfilled, with a positive work environment and colleagues who support and inspire me.'
  },
  {
    id: 'default-topic-2',
    icon: '📣',
    title: 'Communication',
    description:
      'I feel that the company provides transparent and effective communication, enabling me to stay informed and feel included.'
  },
  {
    id: 'default-topic-3',
    icon: '🌍',
    title: 'Culture',
    description:
      'I feel that the company promotes a diverse, inclusive, and positive workplace culture that makes me feel supported and valued.'
  },
  {
    id: 'default-topic-4',
    icon: '🌟',
    title: 'Leadership',
    description:
      'I feel that the company has strong and effective leadership, providing clear guidance and support for me to succeed in my role.'
  },
  {
    id: 'default-topic-5',
    icon: '🎉',
    title: 'Recognition',
    description:
      'The company recognizes and appreciates employee contributions, boosting my confidence and sense of accomplishment.'
  },
  {
    id: 'default-topic-6',
    icon: '🌱',
    title: 'Growth',
    description:
      'I feel that the company encourages and supports employee growth, providing opportunities for learning and development.'
  }
];

const DemoSurvey = () => {
  const modelTemplates = useModels();
  const query = useQuery();
  const id = query.get('id');
  const { meta, models = {} } = modelTemplates;
  const topics = models[id] || DEFAULT_TOPICS;
  const focusedId = topics[0]?.id;

  return (
    <div className="boardMain boardMain-pulse" style={{ borderRadius: '10px' }}>
      <div className="surveyWrapper" style={{ display: 'flex' }}>
        <SurveyView meta={meta} topics={topics} focusedId={focusedId} />
      </div>
    </div>
  );
};

export default DemoSurvey;
