import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from 'components/CustomButtons/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Modal from 'components/Modal/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as CopyIcon } from 'images/icons/copy.svg';
import apiService from 'utils/apiService';
import Log from 'utils/Log';

const apiEndpoint = 'https://api.teleretro.com/v1/metrics';

export default function ApiKeys({ orgId, apiKeys = [] }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [revokeModal, setRevokeModal] = useState(false);
  const [docModal, setDocModal] = useState(false);
  const apiKey = apiKeys[0];

  const handleToggle = (e) => {
    setAnchorEl(open ? null : e.currentTarget);
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onRevokeModal = () => {
    setRevokeModal((prev) => !prev);
    handleClose();
  };

  const onDocModal = () => {
    setDocModal((prev) => !prev);
    handleClose();
  };

  const onDelete = () => {
    apiService
      .delete(`/api/org/${orgId}/api-keys`)
      .then(() => {
        handleClose();

        window.location.reload();
      })
      .catch((e) => {
        Sentry.captureException(e);
        handleClose();
      });
  };

  const onCopy = (msg) => {
    Log('Copy', msg);
  };

  if (!apiKey) return null;

  return (
    <>
      <div className="teamSettings-group">
        <label style={{ marginTop: '4px' }}>API Keys</label>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            value={apiKey.slice(0, 10) + '*'.repeat(20) + apiKey.slice(-5)}
            readOnly
            style={{
              fontSize: '12px',
              background: '#f8f8f8',
              fontFamily: 'Courier',
              padding: '10px 10px',
              border: '1px solid #a7a7a7',
              borderRadius: '10px',
              color: '#333',
              width: '42%',
              marginRight: '4px'
            }}
          />

          <IconButton aria-label="settings" onClick={handleToggle}>
            <MoreVertIcon />
          </IconButton>

          <Popover
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="menu-list-grow">
                <MenuItem className="action-delete" onClick={onRevokeModal}>
                  Revoke key
                </MenuItem>
                <MenuItem onClick={onDocModal}>API guide</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Popover>
        </div>
      </div>

      <Modal status={revokeModal} maxWidth="sm" onClose={onRevokeModal} nonResponsive keepMounted={false}>
        <div className="modalContainer">
          <h2>
            Revoke API Key{' '}
            <span role="img" aria-label="">
              ❌
            </span>
          </h2>

          <p>Are you sure you want to revoke this key?</p>
          <p>This will instantly revoke the key's access to organization data.</p>

          <hr />
          <p>To request new api key please email us at: </p>
          <p>
            <a href="mailto:support@teleretro.com" className="text-button" style={{}}>
              support@teleretro.com
            </a>
          </p>

          <div className="modalContainer-actions">
            <span className="text-button" onClick={onRevokeModal}>
              Cancel
            </span>

            <Button onClick={onDelete}>Confirm</Button>
          </div>
        </div>
      </Modal>

      <Modal
        status={docModal}
        maxWidth="md"
        onClose={onDocModal}
        nonResponsive
        keepMounted={false}
        PaperProps={{
          style: {
            maxWidth: '700px'
          }
        }}
      >
        <div className="modalContainer">
          <h2>
            API Guide{' '}
            <span role="img" aria-label="">
              🔑
            </span>
          </h2>

          <div style={{ textAlign: 'left' }}>
            <p>
              Our organization metrics API provides a snapshot of your team's key performance indicators, including
              individual team action items and retrospective insights.
            </p>
            <h3> 🚀 Making API Requests</h3>
            <p>
              1. Endpoint URL:
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  value={apiEndpoint}
                  readOnly
                  style={{ fontSize: '12px', background: '#f8f8f8', fontFamily: 'Courier' }}
                />

                <Tooltip title="Copy URL" placement="top">
                  <CopyToClipboard
                    text={apiEndpoint}
                    onCopy={() => {
                      onCopy('Api Url');
                    }}
                  >
                    <IconButton>
                      <CopyIcon fontSize="small" />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
              </span>
            </p>
            <p>
              2. Include your API key in the request header <b>X-API-Key</b>:
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  value={apiKey}
                  readOnly
                  style={{ fontSize: '12px', background: '#f8f8f8', fontFamily: 'Courier' }}
                />

                <Tooltip title="Copy Key" placement="top">
                  <CopyToClipboard
                    text={apiKey}
                    onCopy={() => {
                      onCopy('Api Key');
                    }}
                  >
                    <IconButton>
                      <CopyIcon fontSize="small" />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
              </span>
            </p>
            <p>
              Example curl request:
              <code>curl -H "X-API-Key: your_api_key" {apiEndpoint}</code>
            </p>
            <p>
              You can use this API to fetch the latest data, integrate metrics into your dashboards, perform data
              analysis, and automate data collection for reporting.
            </p>
            <h4 style={{ margin: '2rem 0 0.5rem', fontSize: '1em' }}>❗️Security Best Practices</h4>
            <ul style={{ fontSize: '0.9em', lineHeight: '1.2rem' }}>
              <li style={{ listStyle: 'inside' }}>Keep your API key secure and confidential</li>
              <li style={{ listStyle: 'inside' }}>
                Do not embed the API key directly in publicly accessible applications
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
}
