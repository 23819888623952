import React, { useState, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import { format } from 'date-fns';

import { Gif, Carousel } from './TenorComponents'; // '@giphy/react-components';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import viaTenor from 'images/img/tenor.png';
import gifIcon from 'images/img/gif-icon.gif';

import Log from 'utils/Log';

import { TENOR_KEY } from 'config';

const useStyles = makeStyles((theme) => ({
  outer: {
    position: 'relative',
    zIndex: 10
  },
  root: {
    position: 'fixed',
    bottom: '4px',
    width: '100vw',
    pointerEvents: 'none'
  },
  help: {
    position: 'absolute',
    left: '8px',
    bottom: '8px',
    color: '#fff',
    background: 'rgba(0, 0, 0, 0.85)',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    pointerEvents: 'auto',
    marginRight: '4px',
    fontWeight: 'bold',
    '& img': {
      position: 'absolute',
      transition: 'opacity .3s ease-in-out'
    },
    '& svg': {
      fill: '#fff',
      width: '50px',
      transition: 'all .3s ease-in-out',
      transform: 'rotate(-45deg)',
      opacity: '0'
    },
    '&:hover': {
      '& img': {
        opacity: '0'
      },
      '& svg': {
        opacity: '1'
      }
    }
  },
  openIcon: {
    transform: 'rotate(0) !important',
    opacity: '1 !important'
  },
  wrapperOuter: {
    padding: ' 8px 8px 0 66px',
    position: 'relative',
    userSelect: 'none'
  },
  wrapper: {
    display: 'flex',
    padding: '4px 0 8px 12px',
    borderRadius: '10px',
    color: '#fff',
    background: 'rgba(22, 22, 22, 0.85)'
  },
  wrapperSearch: {
    margin: '8px 0 0',
    borderRight: '1px solid rgba(255, 255, 255, 0.3)',
    position: 'relative',
    height: '120px',
    '& input': {
      fontSize: '17px',
      color: '#fff',
      pointerEvents: 'auto',
      marginRight: '10px',
      padding: '4px 8px',
      //width: '160px',
      height: '40px',
      borderRadius: '5px',
      border: '0px none',
      background: 'rgba(255, 255, 255, 0.2)'
    },
    '& img': {
      position: 'absolute',
      bottom: '8px',
      left: '54px'
    }
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    margin: theme.spacing(1)
  }
}));

//mountOnEnter unmountOnExit

const CLIENT_KEY = 'Tenor-gif';

// Search for GIFs by keyword
const searchGifs = async (keyword, type = '', nextPos = 0, limit = 12) => {
  const response = await axios.get(
    `https://tenor.googleapis.com/v2/search?q=${keyword}&key=${TENOR_KEY}&client_key=${CLIENT_KEY}&limit=${limit}&pos=${nextPos}&searchfilter=${type}&media_filter=tinygif,nanogif&contentfilter=low`
  );

  return {
    results: response.data.results,
    next: response.data.next // Include the next position from API response
  };
};

const GifElement = ({ id }) => {
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(`https://tenor.googleapis.com/v2/posts?ids=${id}&key=${TENOR_KEY}&client_key=${CLIENT_KEY}&limit=1`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((e) => e);
  }, [id]);

  return data && data.results.length > 0 ? (
    <Gif gif={data.results[0]} height={120} noLink hideAttribution backgroundColor="transparent" />
  ) : null;
};

const FunPanel = ({ stageGifs, onStage }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [type, setType] = useState('');

  const fetchGifs = (nextPos) => {
    const defaultTopic = format(new Date(), 'EEEE').replace('Wednesday', 'party');
    const topic = keyword || defaultTopic;
    return searchGifs(topic, type, nextPos);
  };

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleToggle = () => {
    setChecked((prev) => !prev);
    setKeyword('');
    //Log('Giphy', 'toggle');
  };

  const selectGif = (gif, e) => {
    onStage({ gifs: [...stageGifs, gif.id] });
    setChecked(false);

    if (keyword) {
      Log('Giphy', keyword);
    }
  };

  const onSelectType = (e) => {
    const value = e.target.value;
    if (value !== null) {
      setType(value);
    }
  };

  // useEffect(() => {
  //   timer = setTimeout(() => {
  //     setChecked(false);
  //   }, 3000); // 3 second
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <div className="giphy-panel">
      <div className={classes.root}>
        <div className={classes.wrapperOuter}>
          <div onClick={handleToggle} className={classes.help}>
            {!checked && <img src={gifIcon} width="34px" alt="gifs" />}
            <CloseIcon className={checked ? classes.openIcon : ''} />
          </div>

          <Slide direction="up" in={checked} timeout={{ enter: 400, exit: 400 }}>
            <div className={classes.wrapper}>
              <div className={classes.wrapperSearch}>
                <input className="giphy-panel--input" value={keyword} type="text" onChange={handleChange} />

                <RadioGroup row aria-label="giphy-type" name="giphy-type" value={type} onChange={onSelectType}>
                  <FormControlLabel
                    value=""
                    control={<Radio color="primary" size="small" disableRipple />}
                    label="Gifs"
                  />
                  <FormControlLabel
                    value="sticker"
                    control={<Radio color="primary" size="small" disableRipple />}
                    label="Stickers"
                  />
                </RadioGroup>

                <img className="giphy-panel--logo" src={viaTenor} alt="via Tenor" width={60} />
              </div>
              <Carousel
                key={`gifphy-${keyword}-${type}`}
                gifHeight={120}
                gutter={10}
                fetchGifs={fetchGifs}
                noLink
                hideAttribution
                onGifClick={selectGif}
                //overlay={Overlay}
                noResultsMessage="😢 Sorry no results"
              />
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default function StagePanel({ stageStates, onStage, disableGifs }) {
  const classes = useStyles();
  const stageGifs = stageStates?.gifs || [];

  return (
    <div className={classes.outer}>
      <div className="giphy-view">
        {stageGifs.map((id, index) => {
          return <GifElement key={`${id}-${index}`} id={id} />;
        })}
      </div>
      {disableGifs === true ? null : <FunPanel stageGifs={stageGifs} onStage={onStage} />}
    </div>
  );
}
