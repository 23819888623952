import React, { useState, useRef, useEffect, useCallback } from 'react';

export const Gif = ({
  gif,
  height = 120,
  noLink = false,
  hideAttribution = false,
  backgroundColor = 'transparent',
  onClick
}) => {
  if (!gif) return null;

  // Get the best available GIF URL
  const gifUrl = gif.media_formats?.tinygif?.url || gif.media_formats?.nanogif?.url || gif.media[0].tinygif.url;

  // Calculate width based on aspect ratio
  const dims = gif.media_formats?.tinygif?.dims || gif.media_formats?.nanogif?.dims || gif.media[0].tinygif.dims;
  const aspectRatio = (dims?.[0] || 1) / (dims?.[1] || 1);
  const width = height * aspectRatio;

  const containerStyle = {
    width,
    height,
    backgroundColor,
    cursor: onClick ? 'pointer' : 'default',
    borderRadius: '4px',
    overflow: 'hidden'
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'block'
  };

  const attributionStyle = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '4px',
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    fontSize: '12px',
    textAlign: 'center'
  };

  const content = (
    <div className="giphy-gif" style={containerStyle} onClick={() => onClick?.(gif)}>
      <img src={gifUrl} alt={gif.title || 'GIF'} style={imageStyle} loading="lazy" />
      {!hideAttribution && gif.content_description && <div style={attributionStyle}>{gif.content_description}</div>}
    </div>
  );

  return noLink ? (
    content
  ) : (
    <a href={gif.itemurl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      {content}
    </a>
  );
};

export const Carousel = ({
  gifHeight = 120,
  gutter = 10,
  fetchGifs,
  noLink = false,
  hideAttribution = false,
  onGifClick,
  noResultsMessage = '😢 Sorry no results'
}) => {
  const [gifs, setGifs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPos, setNextPos] = useState('');
  const containerRef = useRef(null);
  const loadingRef = useRef(false);

  const loadGifs = useCallback(
    async (pos) => {
      if (loadingRef.current) return;

      loadingRef.current = true;
      setLoading(true);

      try {
        const response = await fetchGifs(pos);
        // Get next position from response
        if (response?.next) {
          setNextPos(response.next);
          if (response.results?.length) {
            if (pos === '') {
              // Initial load
              setGifs(response.results);
            } else {
              setGifs((prev) => [...prev, ...response.results]);
            }
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }

      setLoading(false);
      loadingRef.current = false;
    },
    [fetchGifs]
  );

  // Reset when fetchGifs changes
  useEffect(() => {
    setGifs([]);
    setNextPos('');
    loadGifs('');
  }, [loadGifs]);

  // Handle scroll
  const handleScroll = (e) => {
    const container = e.target;

    const scrollThreshold = container.scrollWidth * 0.2;
    const remainingScroll = container.scrollWidth - (container.scrollLeft + container.clientWidth);

    if (remainingScroll < scrollThreshold && !loadingRef.current && nextPos) {
      loadGifs(nextPos);
    }
  };

  // Show no results message only when we have no gifs and we're not loading
  if (!loading && gifs.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
          height: `${gifHeight}px`
        }}
      >
        {noResultsMessage}
      </div>
    );
  }

  return (
    <div className="giphy-carousel">
      <div
        ref={containerRef}
        onScroll={handleScroll}
        style={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          padding: '10px 0',
          gap: `${gutter}px`,
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          WebkitScrollbar: {
            display: 'none'
          }
        }}
      >
        {gifs.map((gif, index) => {
          const gifUrl = gif.media_formats?.tinygif?.url || gif.media_formats?.nanogif?.url || gif.media[0].tinygif.url;
          const aspectRatio =
            (gif.media_formats?.tinygif?.dims?.[0] || 1) / (gif.media_formats?.tinygif?.dims?.[1] || 1);
          const gifWidth = gifHeight * aspectRatio;

          return (
            <div
              key={`${gif.id}-${index}`}
              style={{
                flex: `0 0 ${gifWidth}px`,
                height: `${gifHeight}px`
              }}
              onClick={() => onGifClick?.(gif)}
            >
              <img
                src={gifUrl}
                alt={gif.title || 'GIF'}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '6px',
                  cursor: onGifClick ? 'pointer' : 'default'
                }}
                loading="lazy"
              />
            </div>
          );
        })}
        {loading && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100px',
              height: `${gifHeight}px`
            }}
          >
            Loading...
          </div>
        )}
      </div>
    </div>
  );
};
