import { useState, useEffect } from 'react';

import * as Sentry from '@sentry/browser';
import axios from 'axios';

const DEFAULT_META = {
  intro: {
    icon: '👋',
    title: 'Welcome',
    description:
      'As we seek to improve our ways of working, we genuinely appreciate your honest feedback in this short survey.<br><br> You will be asked to rate each question between 1-5, and feel free to add optional comments. All responses are anonymous.<br><br>Thank you for your time and valuable input!'
  },
  end: {
    icon: '👀',
    title: 'Review',
    description:
      'If you’re happy with your answers, <b>submit</b> to see your team results.<br>You can edit your answers later until the survey is closed.'
  }
};

function useModels() {
  const [models, setModels] = useState();

  useEffect(() => {
    axios
      .get('/static/js/models.json')
      .then(({ data }) => {
        setModels(data);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }, []);

  return { meta: DEFAULT_META, models };
}

export default useModels;
