import React, { useContext } from 'react';
import { nanoid } from 'nanoid';

import Modal from 'components/Modal/Modal';
import 'dashboard/NewBoard.css';
import PulseEditor from './PulseEditor';
import useModels from 'hooks/useModels';
import AppContext from 'AppContext';

const PulseModel = ({ orgId, modelId, pulseModels, closeModal, callback, templateId }) => {
  const { isAuthenticated } = useContext(AppContext);
  const id = modelId || nanoid();
  const data = modelId && Array.isArray(pulseModels) ? pulseModels.filter(({ id }) => id === modelId)[0] : { id };
  const { models = {} } = useModels();
  const templateData = models[templateId] ? { format: { topics: models[templateId] } } : undefined;

  return (
    <Modal
      status={true}
      maxWidth="lg"
      onClose={isAuthenticated ? closeModal : () => {}}
      PaperProps={{
        className: 'pulseEditor-modal'
      }}
      disableEscapeKeyDown
    >
      <PulseEditor
        key={id}
        orgId={orgId}
        data={modelId ? data : templateData || data}
        modelId={modelId}
        callback={callback}
      />
    </Modal>
  );
};

export default PulseModel;
